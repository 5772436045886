import React from 'react';

const Beyond: React.FC = () => {
  React.useEffect(() => {
    window.location.replace('https://cemtuncelli.notion.site/Beyond-Overview-4cf13c5d30ec4dd698482b2a9ba1fa2f');
  }, []);
  return <></>;
};

export default Beyond;
